<template>
  <div>
    <FilterTesseramentiEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TableTesseramentiEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterTesseramentiEconomato from "@/components/components-fit/gestione/economato/FiltriTesseramentiEconomato.vue";
import TableTesseramentiEconomato from "@/components/components-fit/gestione/economato/TableTesseramentiEconomato.vue";

export default defineComponent({
  name: "tesseramenti-economato",
  components: {
    FilterTesseramentiEconomato,
    TableTesseramentiEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);
    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleTesseramenti
    );
    const idSocieta = computed(() => store.getters.idSocietaTesseramenti);

    const startDateEmissione = computed(
      () => store.getters.dataEmissioneDaTesseramenti
    );
    const endDateEmissione = computed(
      () => store.getters.dataEmissioneATesseramenti
    );
    const startDateApprovazione = computed(
      () => store.getters.dataApprovazioneDaTesseramenti
    );
    const endDateApprovazione = computed(
      () => store.getters.dataApprovazioneATesseramenti
    );
    const approvato = computed(() => store.getters.approvatoTesseramenti);
    const meseApprovazione = computed(
      () => store.getters.meseApprovazioneTesseramenti
    );
    const numero = computed(() => store.getters.numeroTesseramenti);
    const causale = computed(() => store.getters.causaleTesseramenti);

    const rowsToSkip = computed(() => store.getters.rowsToSkipTesseramenti);
    const fetchRows = computed(() => store.getters.fetchRowsTesseramenti);
    const sortColumn = computed(() => store.getters.sortColumnTesseramenti);
    const sortOrder = computed(() => store.getters.sortOrderTesseramenti);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_comitato: idComitatoRegionale.value,
        id_societa: idSocieta.value,
        data_ins_da: startDateEmissione.value,
        data_ins_a: endDateEmissione.value,
        data_appr_da: startDateApprovazione.value,
        data_appr_a: endDateApprovazione.value,
        approvato: approvato.value,
        mese: meseApprovazione.value,
        numero: numero.value,
        causale: causale.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_TESSERAMENTI_LIST,
        itemName: "economato_tesseramenti_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersTesseramenti");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_tesseramenti_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipTesseramenti");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
    };
  },
});
</script>
