<template>
  <div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getEconomatoList')"
        >
          <div class="row pb-4">
            <div class="col-sm-5 filter">
              <SelectInput
                placeholder="Comitato"
                :options="comitati"
                :value="idComitatoRegionale"
                name="id_periferico"
                @changeSelect="setComitato"
              />
            </div>
            <div class="col-sm-7 filter">
              <div class="dp__input_wrap text-gray-600">
                <input
                  placeholder="Società"
                  name="nome_societa"
                  type="text"
                  class="form-control"
                  @input="
                    inputFocus = 'societa';
                    getSocieta($event.target.value);
                  "
                  :value="nomeSocieta"
                  autocomplete="off"
                />
                <input
                  name="id_societa"
                  type="hidden"
                  class="form-control"
                  v-model="id_societa"
                />
                <svg
                  @click="setSocieta(null)"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListSocieta
                :focusOnInput="inputFocus === 'societa'"
                :list="societa"
                @hideList="
                  inputFocus = null;
                  resetSocieta;
                "
                @selectedSocieta="
                  setSocieta($event);
                  resetSocieta();
                  inputFocus = null;
                "
              ></ListSocieta>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-sm-3 filter">
              <Datepicker
                v-model="dataEmissioneDa"
                placeholder="Data Inserimento da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataEmissioneDa($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="dataEmissioneA"
                placeholder="Data Inserimento a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataEmissioneA($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3 filter">
              <Datepicker
                v-model="dataApprovazioneDa"
                placeholder="Data approvazione da"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataApprovazioneDa($event)"
                autoApply
              />
            </div>
            <div class="col-sm-3">
              <Datepicker
                v-model="dataApprovazioneA"
                placeholder="Data approvazione a"
                :format="format"
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                inputClassName="h-28"
                @update:modelValue="setDataApprovazioneA($event)"
                autoApply
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 filter">
              <SelectInput
                placeholder="Approvato"
                :options="selectApprovato"
                :value="approvato"
                name="select_approvato"
                @changeSelect="setApprovato"
              />
            </div>
            <div class="col-sm-3 filter">
              <SelectInput
                :options="mesi"
                name="mese_approvazione"
                placeholder="Mese approvazione"
                :value="meseApprovazione"
                @changeSelect="setMeseApprovazione"
              />
            </div>
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Numero"
                name="numero"
                aria-label=""
                @input="setNumero"
                :value="numero"
              />
            </div>
            <div class="col-sm-3 filter">
              <input
                type="text"
                class="form-control"
                placeholder="Causale"
                name="causale"
                aria-label=""
                @input="setCausale"
                :value="causale"
              />
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600"
              @click="$emit('getEconomatoList')"
              :disabled="!loaded"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click.prevent="$emit('resetFilters')"
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import Datepicker from "vue3-date-time-picker";
import { useFormatDateYMD, format } from "@/composables/formatDate";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";

export default defineComponent({
  name: "filtri-tesseramenti-economato",
  emits: ["resetFilters", "getEconomatoList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    Datepicker,
    SelectInput,
    ListSocieta,
  },

  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const stagioneEconomato = computed(() => store.getters.stagioneSelected);
    watch(stagioneEconomato, () => {
      emit("getEconomatoList");
    });

    const id_societa = ref(null);
    const getSocieta = (string) => {
      store.commit("setNomeSocietaTesseramenti", string);
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          id_societa.value = null;
        }
      }
    );

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleTesseramenti
    );
    const idSocieta = computed(() => store.getters.idSocietaTesseramenti);
    const nomeSocieta = computed(() => store.getters.nomeSocietaTesseramenti);
    const dataEmissioneDa = computed(
      () => store.getters.dataEmissioneDaTesseramenti
    );
    const dataEmissioneA = computed(
      () => store.getters.dataEmissioneATesseramenti
    );
    const dataApprovazioneDa = computed(
      () => store.getters.dataApprovazioneDaTesseramenti
    );
    const dataApprovazioneA = computed(
      () => store.getters.dataApprovazioneATesseramenti
    );
    const approvato = computed(() => store.getters.approvatoTesseramenti);
    const meseApprovazione = computed(
      () => store.getters.meseApprovazioneTesseramenti
    );
    const numero = computed(() => store.getters.numeroTesseramenti);
    const causale = computed(() => store.getters.causaleTesseramenti);

    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const setComitato = (event) => {
      store.commit("setIdComitatoRegionaleTesseramenti", event);
    };
    const setSocieta = (event) => {
      store.commit("setSocietaTesseramenti", event);
    };
    const setApprovato = (event) => {
      store.commit("setApprovatoTesseramenti", event);
    };
    const setDataEmissioneDa = (event) => {
      store.commit("setDataEmissioneDaTesseramenti", event);
    };
    const setDataEmissioneA = (event) => {
      store.commit("setDataEmissioneATesseramenti", event);
    };
    const setDataApprovazioneDa = (event) => {
      store.commit("setDataApprovazioneDaTesseramenti", event);
    };
    const setDataApprovazioneA = (event) => {
      store.commit("setDataApprovazioneATesseramenti", event);
    };
    const setMeseApprovazione = (event) => {
      store.commit("setMeseApprovazioneTesseramenti", event);
    };

    const setNumero = (event) => {
      store.commit("setNumeroTesseramenti", event.target.value);
    };
    const setCausale = (event) => {
      store.commit("setCausaleTesseramenti", event.target.value);
    };

    // verifico se le lookup esistono, altrimanti le carico da api
    const keys = ref("c|d|su|ip|sti");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const inputFocus = ref(null);

    return {
      inputFocus,
      isActive: false,
      idComitatoRegionale,
      idSocieta,
      nomeSocieta,
      id_societa,
      societa: computed(() => store.getters.societaNomeCodAff),
      dataEmissioneDa,
      dataEmissioneA,
      dataApprovazioneDa,
      dataApprovazioneA,
      approvato,
      meseApprovazione,
      numero,
      causale,
      setComitato,
      setSocieta,
      setDataEmissioneDa,
      setDataEmissioneA,
      setDataApprovazioneDa,
      setDataApprovazioneA,
      setApprovato,
      setMeseApprovazione,
      setNumero,
      setCausale,
      resetSocieta,
      getSocieta,
      comitati,
      selectApprovato: [
        { id: true, Label: "Si" },
        { id: false, Label: "No" },
      ],
      mesi: [
        { id: "1", label: "Gennaio" },
        { id: "2", label: "Febbraio" },
        { id: "3", label: "Marzo" },
        { id: "4", label: "Aprile" },
        { id: "5", label: "Maggio" },
        { id: "6", label: "Giugno" },
        { id: "7", label: "Luglio" },
        { id: "8", label: "Agosto" },
        { id: "9", label: "Settembre" },
        { id: "10", label: "Ottobre" },
        { id: "11", label: "Novembre" },
        { id: "12", label: "Dicembre" },
      ],
      useFormatDateYMD,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedeconomato_tesseramenti_list")
      ),
    };
  },
});
</script>

<style></style>
