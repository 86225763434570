import services from "../axios/dbManag";

export const approvaMovTesseramento = (id_Movimento) => {
  return services.dbManag
    .post("/economato/movimenti/tesseramento/approva", {
      id_Movimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const deleteMovTesseramento = (id_Movimento) => {
  return services.dbManag
    .post("/economato/movimenti/tesseramento/del", {
      id_Movimento,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
